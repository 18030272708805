import React from "react";

//Customizable Area Start
import { Box, Button, Typography, styled, InputAdornment, IconButton, TextField, InputLabel, } from "@material-ui/core";
import WebForgotPasswordController, { Props } from "./WebForgotPasswordController";
import { TypographyView} from "./PasswordRecovery.web";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { PasswordRecoveryLayout } from "./components/PasswordRecoveryLayout";

const CustomTextField = styled(TextField)({
    marginBottom: '30px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#CBD5E1',
            borderRadius: '8px',
        },
        '&:hover fieldset': {
            borderColor: '#CBD5E1',
            borderRadius: '8px',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#CBD5E1',
            borderRadius: '8px',
        },
    },
    '& .MuiOutlinedInput-input': {
        fontFamily: 'Ubuntu',
        fontSize: '18px',
        padding: '20px 20px',
    },
    '& .MuiFormHelperText-root': {
        color: '#EF4444',
        marginTop: '5px',
        marginBottom: '5px',
        fontSize: '14px',
        background: 'none',
        padding: '0',
    },
});
//Customizable Area End

export default class NewPasswordScreen extends WebForgotPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Star
        //Customizable Area End
    }

    render() {
        return (
            <PasswordRecoveryLayout>
                <TypographyView>
                    <Typography className="title">{'New Password'}</Typography>
                </TypographyView>
                <Box>
                    <InputLabel style={{ marginTop: "25px", marginBottom: "-10px", display: 'flex', alignItems: 'center' }}>
                        <span className="testLabel">New Password</span>
                        <span className="testStar" style={{ position: 'relative', top: '5px' }}>*</span>
                    </InputLabel>
                    <CustomTextField
                        data-test-id='new-value'
                        variant='outlined'
                        fullWidth
                        margin='normal'
                        type={this.state.enablePasswordField ? 'password' : 'text'}
                        placeholder="Your new password"
                        value={this.state.passwordValue}
                        autoComplete="new-password"
                        onChange={(e) => this.handlePasswordChange(e.target.value)}
                        error={!!this.state.passwordError}
                        helperText={this.state.passwordError}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        edge="end"
                                    >
                                        {this.state.enablePasswordField ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box>
                    <InputLabel style={{ marginTop: "-10px", marginBottom: "-10px", display: 'flex', alignItems: 'center' }}>
                        <span className="testLabel">Confirm Password</span>
                        <span className="testStar" style={{ position: 'relative', top: '5px' }}>*</span>
                    </InputLabel>
                    <CustomTextField
                        data-test-id='confirm-value'
                        variant='outlined'
                        fullWidth
                        margin='normal'
                        type={this.state.enableReTypePasswordField ? 'password' : 'text'}
                        placeholder="Confirm your new password"
                        value={this.state.confirmPasswordValue}
                        autoComplete="new-password"
                        onChange={(e) => this.handleConfirmPasswordChange(e.target.value)}
                        error={!!this.state.confirmPasswordError}
                        helperText={this.state.confirmPasswordError}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickConfirmShowPassword}
                                        edge="end"
                                    >
                                        {this.state.enableReTypePasswordField ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button style={{
                        borderRadius: '8px',
                        padding: '10px 20px',
                        backgroundColor: "#E8F6F4",
                        fontFamily: 'Ubuntu',
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '24px',
                        textAlign: 'left',
                        color: '#398378',
                        width: "180px",
                        textTransform: "none",
                        cursor: "pointer"
                    }}
                    onClick={() => this.ResetNewPassword(this.state.resetEmail, this.state.passwordValue, this.state.confirmPasswordValue)}
                    >
                        Enter
                    </Button>
                </Box>
            </PasswordRecoveryLayout>
        );
    }
}