import React from "react";
// Customizable Area Start
import { Box, Button, CircularProgress, InputAdornment, TextField, Typography, styled } from '@material-ui/core';
import { AppHeader } from '../../../components/src/AppHeader.web';
import {
  img1475048925HTpe556x7cBoLvN2ZuRRme,
  img1475048939HTpe556x7cBoLvN2ZuRRme,
  img962c7d73186b2cd6f245df742bac938b,

  imgArrowRight,
  imgI14750489301945250HTpe556x7cBoLvN2ZuRRme,
  imgI14750489311945250HTpe556x7cBoLvN2ZuRRme
} from './assets';
import { Autocomplete } from '@material-ui/lab';
import { AppFooter } from '../../../components/src/AppFooter.web';
import { GalleryBlock } from '../../../components/src/GalleryBlock.web';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import LandingPageController from './LandingPageController';
import { getTransContent, getTranslationConfig } from '../../../components/src/helpers';
import SpaLoginModal from "./components/SpaLoginModal";

export const TypographyView = styled(Typography)({
  '& .heading': {
    fontSize: '36px',
    fontFamily: 'Ubuntu',
    fontWeight: 700,
    color: '#FFF'
  },
  '& .description': {
    fontSize: '16px',
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    color: '#FFF'
  },
  '& .txtErrorMsg': {
    color: '#DC2626',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Ubuntu',
    paddingTop: '5px'
  },
  '& .MuiAutocomplete-option:hover': {
    backgroundColor: '#D9EEEB'
  },
  '& .MuiButton-label': {
    textTransform: 'none'
  },
  '& .title': {
    color: `var(--Basic-Black, #000)`,
    fontFamily: 'Ubuntu',
    fontSize: '24px',
    fontWeight: 700,
  },
  '& .search-block': {
    background: `url(${img1475048925HTpe556x7cBoLvN2ZuRRme}) no-repeat center/cover`,
    maxHeight: '587px',
    minHeight: '450px',
  },
  '& .landing-block': {
    padding: '113px 141px',
  },
  '& .about-block': {
    background: '#ffffff',
    margin: '77px 0',
    padding: '70px 141px'
  },
  '& .info-page-block': {
    fontFamily: 'Ubuntu',
  },
  '& .carousel__inner-slide img': {
    borderRadius: '8px'
  },
  '& .carousel__slide--hidden img': {
    height: '70%',
    animation: '0.2s linear $zoom-in'
  },
  '& .carousel__slide--visible img': {
    height: '100%',
    animation: '0.2s linear $zoom-out'
  },
  '& .carousel__slider-tray': {
    marginLeft: '50%'
  },
  '& .carousel__inner-slide': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .carousel__slider-tray-wrapper': {
    width: '50vw'
  },
  '@global': {
    '@keyframes zoom-in': {
      '0%': {
        height: '100%'
      },
      '100%': {
        height: '70%',
      }
    },
    '@keyframes zoom-out': {
      '0%': {
        height: '70%',
      },
      '100%': {
        height: '100%'
      }
    },
    '*::-webkit-scrollbar': {
      width: '5px'
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: '#D9EEEB',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#398378',
      outline: '1px solid slategrey'
    }
  }
});

interface Props {
  navigation: any;
  id: string;
}

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);

    this.goToCategoryVenues = this.goToCategoryVenues.bind(this);
  }

  async componentDidMount() {
    await this.getLandingPageData();
    await this.getCustomerDetails();
    await this.getContentManagementDetails();
    await this.getContactDetails();
    await this.getCrruntPlanDetails();
    await this.getSpaDetailsByCountry()
  }

  handleProfileClick = () => {
    this.props.navigation.navigate('SpaUserProfile');
  };

  render() {
    const { t, dir, isArabic } = getTranslationConfig();
    const { about_us, products } = this.state.landingPageData;
    const aboutUsContent = getTransContent(about_us);
    const couponSlides = this.getResultVouchers()
    const uniqueSpaOptions = [...new Map(this.state.spaOptions.map((option: any) => [option.label, { label: option.label }])).values()];
    const uniqueLocationOptions = [...new Map(this.state.locationOptions.map((option: any) => [option, { label: option }])).values()];

    const joinUsObject = getTransContent(this.getJoinUsContent()?.attributes);
    const whyChooseUsObject = getTransContent(this.getWhyChooseUsContent()?.attributes);
    const whyChooseUsData = this.getWhyChooseUsContent();

    // Custom style sheets
    const styles = {
      SafeAreaView: {
        backgroundColor: '#e8f6f4ff'
      },
      inputSearch: {
        borderRadius: '8px',
        borderBottom: '1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)',
        width: '17vw'
      }
    };
    // Component code
    const profilePic = this.state.customerDetails?.attributes?.profile_photo?.url;

    return (
      <TypographyView>
        <Box style={styles.SafeAreaView} className='info-page-block'>
          <AppHeader
            onClickEvent={() => this.goToLogin()}
            onProfileClick={this.handleProfileClick}
            profileImageUrl={profilePic}
            forceUpdate={this.forceUpdate.bind(this)}
            context={this}
          />
          <Box
            dir={dir}
            data-view-name={{ viewName: 'group_' }}
          >
            <Box className='search-block landing-block'>
              <Typography
                style={{
                  width: 400,
                  fontFamily: 'Ubuntu',
                  fontWeight: 700,
                  fontSize: 48,
                  color: '#ffffffff',
                }}
              >{t['landing-page-find-service-title']}</Typography>
              <Typography
                style={{
                  padding: '30px 0',
                  width: 551,
                  fontFamily: 'Ubuntu',
                  fontWeight: 400,
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#ffffffff',
                }}
              >
                {t['landing-page-find-service-title-description']}
              </Typography>
              <Box height={83} display='flex' justifyContent='center' alignItems='center' borderRadius='12px' width='50vw' gridGap={10} style={{ backgroundColor: '#ffffff' }}>
                <Box>
                  <Typography style={{ color: '#398378', fontSize: '14px', fontWeight: '700' }}>
                    {t['landing-page-find-service-name']}
                  </Typography>
                  <Autocomplete
                    id="company-search"
                    options={uniqueSpaOptions}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    disablePortal
                    value={this.state.searchName}
                    onChange={(event, newValue) => {
                      let searchName = '';
                      let searchId = null;

                      if (typeof newValue === 'object' && newValue !== null) {
                        searchName = newValue.label;
                      } else if (typeof newValue === 'string') {
                        searchName = newValue;
                      }

                      this.setState({ searchName, searchId });

                    }}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ searchName: newInputValue }, () => {
                        this.handleSpaSearch(newInputValue, this.state.spaLocation || '');
                      });
                    }}
                    getOptionLabel={(option) => {
                      return typeof option === 'string' ? option : `${option.label}`;
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          placeholder={t['landing-page-find-service-placeholder']}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img width={20} height={20} src={imgI14750489301945250HTpe556x7cBoLvN2ZuRRme} alt="" />
                              </InputAdornment>
                            )
                          }}
                          fullWidth
                          style={{
                            ...styles.inputSearch
                          }}
                        />
                      </div>
                    )}
                  />

                </Box>
                <Box>
                  <Typography style={{ color: '#398378', fontSize: '14px', fontWeight: '700' }}>
                    {t['landing-page-location-name']}
                  </Typography>
                  <Autocomplete
                    id="location-search"
                    options={uniqueLocationOptions}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    disablePortal
                    freeSolo
                    value={this.state.spaLocation}
                    onChange={(event, newValue) => {
                      if (newValue && typeof newValue === 'object' && 'label' in newValue) {
                        this.setState({ spaLocation: newValue.label }, () => {
                        });
                      } else {
                        this.setState({ spaLocation: newValue || '' }, () => {
                        });
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ spaLocation: newInputValue }, () => {
                        this.handleSpaSearch(this.state.searchName, newInputValue || '');
                      });
                    }}
                    getOptionLabel={(option) => {
                      return typeof option === 'string' ? option : `${option.label}`;
                    }}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          placeholder={t['landing-page-location-placeholder']}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img width={20} height={20} src={imgI14750489311945250HTpe556x7cBoLvN2ZuRRme} alt="" />
                              </InputAdornment>
                            )
                          }}
                          fullWidth
                          style={{
                            ...styles.inputSearch
                          }}
                        />
                      </div>
                    )}
                  />
                </Box>
                <Button
                  data-test-id='search-btn'
                  style={{
                    height: 59, width: 130, borderRadius: 8, backgroundColor: '#398378ff',
                    color: '#ffffffff',
                    opacity: (this.state.searchName || this.state.spaLocation) ? 1 : 0.5,
                  } as React.CSSProperties}
                  onClick={() => { this.handleSearchAndNavigate() }}
                  disabled={!this.state.searchName && !this.state.spaLocation || this.state.loading}
                >
                  {this.state.loading ? (
                    <CircularProgress size={24} style={{ color: '#ffffff' }} />
                  ) : (
                    t['landing-page-search-btn']
                  )}
                </Button>
              </Box>

            </Box>

            <Box className='landing-block about-block' display='flex' justifyContent='space-between'>
              <Box>
                <Typography
                  style={{
                    fontFamily: 'Ubuntu',
                    fontSize: '40px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    color: '#000000ff',
                    marginBottom: '32px'
                  }}
                >
                  {t['landing-page-about-us-title']}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'Ubuntu',
                    fontWeight: '400',
                    fontSize: 16,
                    color: '#000000ff',
                    marginBottom: '50px',
                    marginRight: '16px'
                  }}
                >{aboutUsContent.description}</Typography>
                <Button
                  style={{ 
                    height: 56, width: 163, borderRadius: 8,
                    backgroundColor: '#398378ff', color: '#ffffffff'
                  }}
                  onClick={() => this.goToLearnMorePage()}
                >
                  {t['landing-page-about-us-more-btn']}
                </Button>
              </Box>
              <img
                style={{ width: '48%', display: 'block' }}
                src={img1475048939HTpe556x7cBoLvN2ZuRRme}
              />
            </Box>

            {/* TODO: change the image */}
            <Box pt={5} pb={7} style={{
              background: `url(${img962c7d73186b2cd6f245df742bac938b}) center/cover`,
              textAlign: 'center',
            }}>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontWeight: '700',
                  fontSize: 48,
                  color: '#ffffffff',
                  marginBottom: '40px'
                }}
              >{t['landing-page-discounts-title']}</Typography>
              <Box>
                <CarouselProvider
                  infinite
                  naturalSlideWidth={70}
                  naturalSlideHeight={38.5}
                  totalSlides={couponSlides.length}
                  currentSlide={this.state.selectedImage}
                >
                  <Slider>
                    {couponSlides.map((couponImg, index) => (
                      <Slide key={couponImg + index} index={index}>
                        <img
                          src={couponImg}
                          alt="advertisement image"
                          onClick={() => this.handleSlideClick(index)}
                          style={{
                            cursor: 'pointer',
                          }}
                        />
                      </Slide>
                    ))}
                  </Slider>
                </CarouselProvider>
              </Box>
            </Box>

            <Box className='landing-block' style={{ paddingBottom: '48px' }}>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontWeight: 700,
                  fontSize: 48,
                  color: '#000000',
                  marginTop: "-40px"
                }}
              >{t['landing-page-find-service-title']}</Typography>
              <Typography
                style={{
                  padding: '30px 0',
                  fontFamily: 'Ubuntu',
                  fontWeight: 400,
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#000000',
                }}
              >
                {t['landing-page-find-service-description']}
              </Typography>
              {/* Check product name after BE implementation */}
              <GalleryBlock
                products={products}
                buttons={[
                  {
                    title: t['landing-page-services-btn'],
                    handler: this.goToCategoryVenues.bind(this)
                  }
                ]}
              />

            </Box>

            <Box className='landing-block' style={{
              background: `url(${img962c7d73186b2cd6f245df742bac938b}) center/cover`,
              textAlign: 'center'
            }}>
              {joinUsObject &&
                <>
                  <Typography
                    style={{
                      fontFamily: 'Ubuntu',
                      fontWeight: 500,
                      fontSize: 48,
                      color: '#ffffffff',
                    }}
                  >{`"${joinUsObject.title}"`}</Typography>
                  <Typography
                    style={{
                      padding: '30px 0',
                      fontFamily: 'Ubuntu',
                      fontWeight: 400,
                      fontSize: 16,
                      letterSpacing: 0,
                      color: '#ffffffff',
                    }}
                  >
                    {joinUsObject.description}
                  </Typography>
                </>
              }
              <Button
                style={{
                  padding: '16px',
                  whiteSpace: 'pre',
                  borderRadius: 8,
                  backgroundColor: '#398378ff',
                  color: '#ffffffff',
                  width: '45%'
                } as React.CSSProperties}
                onClick={() => this.setOpenLoginModal(true)}
              >
                {t['landing-page-join-us-title']}
                <img
                  src={imgArrowRight}
                  style={{
                    paddingLeft: '32px',
                    ...(isArabic && {
                      transform: 'rotate(180deg)'
                    })
                  }} />
              </Button>
            </Box>

            {whyChooseUsObject && (
              <Box className='landing-block'>
                <Typography
                  style={{
                    fontFamily: 'Ubuntu',
                    fontSize: '40px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    color: '#000000ff',
                    marginBottom: '32px'
                  }}
                >{whyChooseUsObject.title}</Typography>
                <Typography
                  style={{
                    fontFamily: 'Ubuntu',
                    fontWeight: '400',
                    fontSize: 16,
                    letterSpacing: 0,
                    color: '#000000ff',
                    marginBottom: '40px'
                  }}
                >{whyChooseUsObject.description}</Typography>

                <Box
                  display='grid'
                  style={{
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: '28px',
                  }}
                  textAlign='center'
                >
                  {whyChooseUsData?.attributes?.images?.map((image, index) => (
                    <Box key={index} >
                      <img src={image.url} alt="service image" width='100%' height="150px" />
                      <Typography
                        style={{
                          fontFamily: 'Ubuntu',
                          fontWeight: 400,
                          fontSize: 16,
                          marginTop: '25px'
                        }}
                      >
                        {getTransContent(image).name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
          <SpaLoginModal
            navigation={this.props.navigation}
            id={''}
            openLoginModal={this.state.openLoginModal}
            setOpenLoginModal={this.setOpenLoginModal}
          />
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView>
    );
  }
}

// Customizable Area End
