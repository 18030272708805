import React from "react"
// Customizable Area Start
import './SubCategories.css'
import { AppHeader } from '../../../components/src/AppHeader.web'

import { AppFooter } from '../../../components/src/AppFooter.web'
import LandingPageController from "./LandingPageController"
import {
    imgI147504895236797486HTpe556x7cBoLvN2ZuRRme, partTwoLearnMoreImgOne,
    partTwoLearnMoreImgTwo, partTwoLearnMoreImgThree, partTwoLearnMoreImgCheckBox,
    partTwoLearnMoreImgPhone, partThreeLearnBackground, partThreeLearnProfileImage
} from './assets'
import { BreadCrumbs } from "../../../components/src/BreadCrumbs.web"
import { Box, Button, Card, CardContent, Typography, Grid, Pagination } from '@mui/material';
import Loader from "../../../components/src/Loader.web";
import { Home } from "@material-ui/icons"
import MoreVertIcon from '@material-ui/icons/MoreVert';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { List, ListItem, ListItemText } from "@material-ui/core"
import SpaLoginModal from "./components/SpaLoginModal"

interface Props {
    navigation: any;
    id: string
}

export default class LearnMore extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }


    navigationMap = {
        Home: 'LandingPage',
    };

    handleNavigate = (route: string) => {
        this.props.navigation.navigate(route);
    };

    render() {

        const steps = [
            { id: 1, title: 'Step 1 (Sign up)', content: 'Create your spa profile and provide details about your services, location, and amenities. Gain exposure to a wider audience of potential customers searching for spa services worldwide and Keep your clients coming back for more with our convenient booking and payment system, personalized promotions, and rewards programs.' },
            { id: 2, title: 'Step 2 (List your services)', content: 'Add the services you offer, set pricing, and specify the details clients need to know.' },
            { id: 3, title: 'Step 3 (Receive Bookings)', content: 'Start receiving bookings directly through our platform, with a simple and streamlined process.' },
            { id: 4, title: 'Step 4 (Manage Schedule)', content: 'Easily manage your schedule, block unavailable dates, and handle client bookings.' },
            { id: 5, title: 'Step 5 (Grow your Business)', content: 'Gain exposure to a wider audience and keep clients coming back with promotions and rewards.' },
        ];

        const { activeStep } = this.state;
        const activeContent = steps.find((step) => step.id === activeStep);

        return (
            <Box style={{ backgroundColor: "#E8F6F4", height: "full" }}>
                <AppHeader
                    context={this}
                    onClickEvent={() => this.goToLogin()}
                    forceUpdate={this.forceUpdate.bind(this)}
                />
                <Box>
                    <Box className="first-scetion"
                        style={{
                            backgroundImage: `url(${imgI147504895236797486HTpe556x7cBoLvN2ZuRRme})`,
                            backgroundSize: 'cover',
                            width: '100vw',
                            maxHeight: '70px',
                            minHeight: '450px',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '10% 105%',
                            marginBottom: "10px"
                        }}
                    >
                        <Box style={{ paddingTop: "50px", paddingLeft: "100px" }}>
                            <BreadCrumbs
                                items={["Home", "About Us"]}
                                targetColor="#FFFFFF"
                                navigationMap={this.navigationMap}
                                navigate={this.handleNavigate}
                            />
                        </Box>
                        <Box>
                            <Typography
                                style={{
                                    fontFamily: 'Ubuntu',
                                    fontWeight: 700,
                                    fontSize: '48px',
                                    lineHeight: '56px',
                                    letterSpacing: '-1.5%',
                                    color: "#FFFFFF",
                                    marginTop: "70px",
                                    paddingLeft: "100px"
                                }}
                            >
                                About Us
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: 'Ubuntu',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    letterSpacing: '-1.5%',
                                    color: "#FFFFFF",
                                    marginTop: "10px",
                                    paddingLeft: "100px",
                                    width: "500px"
                                }}
                            >
                                SPAroof, your premier destination for spa enthusiasts worldwide. We're thrilled to invite you to join our platform and showcase your spa to our community of wellness seekers.
                            </Typography>

                        </Box>
                    </Box>
                    <Box className="second-scetion" display="flex" justifyContent="space-between" mb={6} px={12}>
                        <Box className="left" flex={1}>
                            <Box>
                                <Typography style={{
                                    fontFamily: 'Ubuntu',
                                    fontSize: '36px',
                                    fontWeight: 700,
                                    lineHeight: '44px',
                                    letterSpacing: '-0.01em',
                                    textAlign: 'left'
                                }}>
                                    Who we are and what we do
                                </Typography>
                                <Typography style={{
                                    fontFamily: 'Ubuntu',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    textAlign: 'left',
                                    marginTop: "50px"
                                }}>
                                    Welcome to SPAroof, your global destination for indulgent spa experiences and holistic wellness. Our mission is to connect spa enthusiasts with top-rated spas around the world, offering a curated selection of the finest establishments spanning continents. With SPAroof, users can seamlessly discover, book, and enjoy rejuvenating treatments and services, no matter where they are.
                                    <br />
                                    <br />
                                    Founded on the principles of quality, convenience, and community, SPAroof is more than just a booking platform – it's a vibrant global community of spa enthusiasts who share a passion for wellness and self-discovery. We curate an unparalleled selection of spas that meet our stringent standards of excellence, ensuring every experience is extraordinary, whether you're in Paris, Tokyo, New York, or anywhere in between.
                                </Typography>
                            </Box>
                            <Box>
                                <Box display="flex" justifyContent="space-between" mt={5}>
                                    <Box display="flex" alignItems="center">
                                        <img src={partTwoLearnMoreImgCheckBox} alt="checkobox" />
                                        <Typography style={{
                                            fontFamily: 'Ubuntu',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            lineHeight: '24px',
                                            marginLeft: "10px"
                                        }}>Ambience and Equipment</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <img src={partTwoLearnMoreImgCheckBox} alt="checkobox" />
                                        <Typography style={{
                                            fontFamily: 'Ubuntu',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            lineHeight: '24px',
                                            marginLeft: "10px"
                                        }}>Ambience and Equipment</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mt={5}>
                                    <Box display="flex" alignItems="center">
                                        <img src={partTwoLearnMoreImgCheckBox} alt="checkobox" />
                                        <Typography style={{
                                            fontFamily: 'Ubuntu',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            lineHeight: '24px',
                                            marginLeft: "10px"
                                        }}>Ambience and Equipment</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <img src={partTwoLearnMoreImgCheckBox} alt="checkobox" />
                                        <Typography style={{
                                            fontFamily: 'Ubuntu',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            lineHeight: '24px',
                                            marginLeft: "10px"
                                        }}>Ambience and Equipment</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mt={5}>
                                    <Box display="flex" alignItems="center">
                                        <img src={partTwoLearnMoreImgCheckBox} alt="checkobox" />
                                        <Typography style={{
                                            fontFamily: 'Ubuntu',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            lineHeight: '24px',
                                            marginLeft: "10px"
                                        }}>Ambience and Equipment</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <img src={partTwoLearnMoreImgCheckBox} alt="checkobox" />
                                        <Typography style={{
                                            fontFamily: 'Ubuntu',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            lineHeight: '24px',
                                            marginLeft: "10px"
                                        }}>Ambience and Equipment</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '8px',
                                    padding: '20px',
                                    gap: '20px',

                                }}
                                mt={5}
                            >
                                <Box display="flex" alignItems="center" >
                                    <img src={partTwoLearnMoreImgPhone} alt="phone" />
                                    <Box ml={2}>
                                        <Typography
                                            style={{
                                                fontFamily: 'Ubuntu',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                lineHeight: '24px',
                                                textAlign: 'left',
                                                color: "#0F172A",
                                                cursor:"pointer"
                                            }}
                                            onClick={() => this.goToContactUsPage()}
                                        >
                                            Have any question ? Contact Us
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontFamily: 'Ubuntu',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                lineHeight: '24px',
                                                textAlign: 'left',
                                                color: "#64748B"
                                            }}
                                        >
                                            (123) 345-6789
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box>
                                    <MoreVertIcon style={{ color: '#475569' }} />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            className="right"
                            sx={{
                                position: 'relative',
                                width: '100%',
                                height: '500px',
                                flex: 1,
                                marginTop: "55px"
                            }}
                        >
                            <Box
                                component="img"
                                src={partTwoLearnMoreImgOne}
                                alt="Image 1"
                                sx={{
                                    position: 'absolute',
                                    top: '0px',
                                    right: '0px',
                                    width: '70%',
                                    height: 'auto',
                                    zIndex: 1,

                                }}
                            />
                            <Box
                                component="img"
                                src={partTwoLearnMoreImgTwo}
                                alt="Image 2"
                                sx={{
                                    position: 'absolute',
                                    top: '90px',
                                    left: '50px',
                                    width: '60%',
                                    height: 'auto',
                                    zIndex: 2,
                                }}
                            />
                            <Box
                                component="img"
                                src={partTwoLearnMoreImgThree}
                                alt="Image 3"
                                sx={{
                                    position: 'absolute',
                                    top: '300px',
                                    left: '200px',
                                    width: '55%',
                                    height: 'auto',
                                    zIndex: 3,

                                }}
                            />

                        </Box>
                    </Box>
                    <Box className="first-scetion"
                        style={{
                            backgroundImage: `url(${partThreeLearnBackground})`,
                            backgroundSize: 'cover',
                            width: '100vw',
                            height: '800px',
                            backgroundRepeat: 'no-repeat',
                            marginBottom: "10px"
                        }}
                    >
                        <Box px={10} pt={5}>
                            <Typography
                                style={{
                                    fontFamily: 'Ubuntu',
                                    fontSize: '36px',
                                    fontWeight: 700,
                                    lineHeight: '44px',
                                    letterSpacing: '-0.01em',
                                    textAlign: 'left',
                                    color: "#FFFFFF"
                                }}
                            >
                                BECOME A SPA OWNER !
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: 'Ubuntu',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    color: "#FFFFFF",
                                    marginTop: "20px"
                                }}
                            >
                                Are you a spa owner looking to expand your reach and grow your business? Joining SPAroof is your ticket to increased visibility, boosted bookings, and enhanced customer loyalty. Sign up today and discover the power of partnership.
                            </Typography>
                        </Box>
                        <Box display="flex" px={10} mt={5}>
                            <Box mr={2}>
                                <Box width="30%" mt={2}>
                                    <List >
                                        {steps.map((step) => (
                                            <ListItem
                                                key={step.id}
                                                button
                                                onClick={() => this.handleStepClick(step.id)}
                                                style={{
                                                    width: activeStep === step.id ? '410px' : '400px',
                                                    backgroundColor: activeStep === step.id ? '#E8F6F4' : '#FFFFFF',
                                                    borderBottom: '1px solid #ddd',
                                                    borderLeft: activeStep === step.id ? '4px solid #398378' : "",
                                                    padding: "30px"
                                                }}
                                            >
                                                <KeyboardArrowRightIcon style={{
                                                    fontSize: '24px',
                                                    marginLeft: '8px',
                                                    color: "#398378",
                                                    marginRight: "10px",
                                                    fontWeight: 'bold'
                                                }} />
                                                <Typography style={{
                                                    fontFamily: 'Ubuntu',
                                                    fontSize: '16px',
                                                    fontWeight: 700,
                                                    lineHeight: '24px',
                                                    textAlign: 'left',
                                                    color: "#0F172A",
                                                }}>
                                                    {step.title}
                                                </Typography>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                                <Box mt={2} textAlign="center">
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#398378',
                                            borderRadius: '8px',
                                            padding: '16px',
                                            width: '350px',
                                            textTransform: 'none',
                                            fontSize: '16px',
                                            fontWeight: 700,
                                            lineHeight: '24px',
                                            fontFamily: 'Ubuntu',
                                            color: '#FFFFFF',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            '&:hover': {
                                                backgroundColor: '#398378',
                                                color: '#FFFFFF',
                                            },
                                        }}
                                        onClick={() => this.setOpenLoginModal(true)}
                                    >
                                        Join as a Spa Owner
                                        <KeyboardArrowRightIcon style={{ fontSize: '16px', fontWeight: 700, marginLeft: '8px' }} />
                                    </Button>
                                </Box>
                            </Box>
                            <Box width="60%" p={3} ml={10} bgcolor="#E8F6F4" >
                                <Box display="flex" gap={2} position="relative">
                                    <Box
                                        component="img"
                                        src={partThreeLearnProfileImage}
                                        alt="Image 3"
                                        sx={{
                                            position: 'absolute',
                                            top: '100px',
                                            left: '-100px',
                                            width: 'auto',
                                            height: 'auto',
                                            zIndex: 3,
                                            boxShadow: 1
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '100px',
                                            left: '150px',
                                            zIndex: 4,
                                            textAlign: 'left',
                                            width: 'calc(100% - 220px)',
                                            marginLeft: "70px"
                                        }}
                                    >
                                        <Typography variant="h5" fontWeight="bold">
                                            {activeContent?.title.split('(')[1].slice(0, -1)}
                                        </Typography>
                                        <Typography variant="body1" mt={1}>
                                            {activeContent?.content}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <SpaLoginModal
                    navigation={this.props.navigation}
                    id={''}
                    openLoginModal={this.state.openLoginModal}
                    setOpenLoginModal={this.setOpenLoginModal}
                />
                <AppFooter navigation={this.props.navigation} />
            </Box >
        );

    }
}
