import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData, setStorageData } from '../../../framework/src/Utilities';


// Customizable Area Start
import * as Yup from "yup";
import { getTranslationConfig } from '../../../components/src/helpers';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  emailError: boolean;
  open: boolean;
  apiMsg: string;
  enableReTypePasswordField: boolean,
  passwordValue: string;
  confirmPasswordValue: string;
  resetEmail: string
  passwordError: string | null;
  confirmPasswordError: string | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class WebForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean = false;
  labelTitle: string = "";
  textHeading: string = "";
  formTiltle: string = "";
  txtEmail: string = "";
  txtPassword: string = "";
  description: string = "";
  star: string = "";
  emailErrorMsg = "";
  accountText: string = "";
  apiResetPasswordCallId = "";
  apiSendRecoveryCallId = "";
  private resolveRecoveryEmail: ((value: any) => void) | null = null;
  private rejectRecoveryEmail: ((reason?: any) => void) | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchem

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      emailError: false,
      open: false,
      apiMsg: '',
      enableReTypePasswordField: true,
      passwordValue: '',
      confirmPasswordValue: '',
      resetEmail: "",
      passwordError:null,
      confirmPasswordError: null,
    };
    this.labelTitle = configJSON.labelTitle;
    this.textHeading = configJSON.textHeading;
    this.formTiltle = configJSON.formTiltle;
    this.txtEmail = configJSON.txtEmail;
    this.txtPassword = configJSON.txtPassword;
    this.description = configJSON.description;
    this.star = configJSON.star;
    this.emailErrorMsg = configJSON.emailErrorMsg;
    this.accountText = configJSON.accountText;
    // Customizable Area End
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const emailFromUrl = urlParams.get('email');


    if (emailFromUrl !== null) {
      this.setState({
        resetEmail: emailFromUrl,
      });
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.apiSendRecoveryCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        if (this.resolveRecoveryEmail) {
          this.resolveRecoveryEmail(responseJson);
        }
      } else {
        const errorMessage = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (this.rejectRecoveryEmail) {
          this.rejectRecoveryEmail(errorMessage || "Unknown error occurred");
        }
      }

      this.resolveRecoveryEmail = null;
      this.rejectRecoveryEmail = null;
    }

    if (apiRequestCallId === this.apiResetPasswordCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (responseJson) {
        const msg: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.NavigationPayLoadMessage), {
          messageType: 'success',
          message: 'Password updated successfully',
        });
        this.send(msg);
      }
    }
  }


  // Customizable Area Start
  setEmail = (text: string) => {
    this.setState({
      emailValue: text, emailError: false
    });
  };
  onsubmit = () => {
  }
  onRecovery = () => {
    if (this.state.emailValue == '') {
      this.setState({ emailError: true })
    } else {
      this.setState({ emailError: false })
    }

  }
  handleClose = () => {
    this.setState({ open: false })
  }

  goToResendLinkPage = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationRecoveryPage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToNewPasswordPage = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationNewPasswordPage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      enablePasswordField: !prevState.enablePasswordField,
    }));
  };

  handleClickConfirmShowPassword = () => {
    this.setState((prevState) => ({
      enableReTypePasswordField: !prevState.enableReTypePasswordField,
    }));
  };

  handlePasswordChange = (value: string) => {
    this.setState({ 
      passwordValue: value,
      passwordError: null
    }, () => {
      this.validateUserPassword(value);
      if (this.state.confirmPasswordValue) {
        this.validateConfirmPassword(this.state.confirmPasswordValue);
      }
    });
  };

  handleConfirmPasswordChange = (value: string) => {
    this.setState({ 
      confirmPasswordValue: value,
      confirmPasswordError: null
    }, () => {
      this.validateConfirmPassword(value);
    });
  };


  async sendRecoveryEmail(email: string) {
    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };

      const formData = `data[email]=${email}`;

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiSendRecoveryCallId = requestMessage.messageId;


      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sendRecoveryEmailEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      this.resolveRecoveryEmail = resolve;
      this.rejectRecoveryEmail = reject;
    });
  }


  ResendPasswordButtonClick = async (email: string) => {
    const response = await this.sendRecoveryEmail(email);
    if (response && (response as { message: string }).message === 'Password reset link sent successfully') {
      await removeStorageData("ResetPasswordEmail");
      this.goToNewPasswordPage();
    }
  }

  validateEmail = (email: string) => {
    if (!email) return false;
    if (!email.includes('@')) return false;

    const [localPart, domain] = email.split('@');
    if (!localPart) return false;

    const domainParts = domain.split('.');
    if (domainParts.length < 2) return false;
    if (domainParts[domainParts.length - 1].length < 2) return false;

    if (localPart.includes(' ')) return false;
    if (domain.includes(' ')) return false;

    return true;
  }

  handleButtonClick = () => {
    if (this.validateEmail(this.state.emailValue)) {
      setStorageData('ResetPasswordEmail', this.state.emailValue);
      this.sendRecoveryEmail(this.state.emailValue);
      this.goToResendLinkPage();
    } else {
      this.setState({ emailError: true });
    }
  }

  async ResetNewPassword(email: string, password: string, confirmPassword: string) {
    const isPasswordValid = this.validateUserPassword(password);
    const isConfirmPasswordValid = this.validateConfirmPassword(confirmPassword);

    if (!isPasswordValid || !isConfirmPasswordValid) {
      return;
    }
    
    const headers = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetPasswordCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendResetNewPasswordEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        email,
        password,
        confirm_password: confirmPassword,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  validateConfirmPassword = (confirmPassword: string): boolean => {
    const { passwordValue } = this.state;
    const { t } = getTranslationConfig();    

    if (!confirmPassword) {
      this.setState({ 
        confirmPasswordError: t['password-confirm-required'] 
      });
      return false;
    }

    if (confirmPassword !== passwordValue) {
      this.setState({ 
        confirmPasswordError: t['passwords-do-not-match']
      });
      return false;
    }

    this.setState({ confirmPasswordError: null });
    return true;
  };

  validateUserPassword = (password: string): boolean => {
    const { t } = getTranslationConfig();
    password = password.trim();
  
    this.setState({ passwordError: null });
  
    const validationRules = [
      {
        condition: !password,
        errorMessage: t['password-required'],
      },
      {
        condition: password.length < 8,
        errorMessage: t['profile-settings-password-validation-8-chars'],
      },
      {
        condition: !this.hasMixedCharacterTypes(password),
        errorMessage: t['profile-settings-password-validation-full-rules'],
      },
      {
        condition: this.hasConsecutiveIdenticalCharacters(password),
        errorMessage: t['profile-settings-password-validation-identical'],
      },
      {
        condition: this.isCommonPassword(password),
        errorMessage: t['profile-settings-password-validation-common'],
      },
      {
        condition: this.hasSequentialCharacters(password),
        errorMessage: t['profile-settings-password-validation-sequential'],
      },
    ];
  
    for (const rule of validationRules) {
      if (rule.condition) {
        this.setState({ passwordError: rule.errorMessage });
        return false;
      }
    }
  
    return true;
  };
  
  hasMixedCharacterTypes(password: string): boolean {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
  
    let hasUppercase = false;
    let hasLowercase = false;
    let hasNumber = false;
  
    for (const char of password) {
      if (uppercaseChars.includes(char)) hasUppercase = true;
      else if (lowercaseChars.includes(char)) hasLowercase = true;
      else if (numberChars.includes(char)) hasNumber = true;
    }
  
    return this.getAllCombinedLogicalValues(hasUppercase, hasLowercase, hasNumber);
  }
  
  hasConsecutiveIdenticalCharacters(password: string): boolean {
    for (let i = 0; i < password.length - 2; i++) {
      if (password[i] === password[i + 1] && password[i] === password[i + 2]) {
        return true;
      }
    }
    return false;
  }
  
  isCommonPassword(password: string): boolean {
    const commonPasswords = ['password', '123456', 'qwerty', 'admin'];
    return commonPasswords.includes(password.toLowerCase());
  }
  
  hasSequentialCharacters(password: string): boolean {
    const sequences = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < sequences.length - 2; i++) {
      const fragment = sequences.substring(i, i + 3);
      if (password.toLowerCase().includes(fragment)) {
        return true;
      }
    }
    return false;
  }

  getAllCombinedLogicalValues(...conditions: boolean[]): boolean {
    return conditions.every(condition => condition);
  }

  // Customizable Area End
}
